"use client";

import {
  Avatar,
  BottomNavigation,
  BottomNavigationAction,
  Container,
} from "@mui/material";
import * as React from "react";
import { usePathname } from "next/navigation";
import Link from "next/link";
import { useConfigStore } from "@/stores/configStore";
import { useAuthStore } from "@/stores/authStore";
import { shallow } from "zustand/shallow";
import { useAppModalStore } from "@/stores/appModalStore";
import { Icon } from "@iconify/react";
import { useAuthModalStore } from "@/stores/authModalStore";

export interface IAppProps { }

type BottomNavigationAction = {
  path: string;
  label: string;
  icon: string;
  color: string | null
  image?: string
};

const bottomNavigationActions: BottomNavigationAction[] = [
  {
    path: "/",
    label: "แพ็คเกจ",
    icon: "noto:wrapped-gift",
    color: null,
    image: 'https://auto.tem-max.net/webicons/ic_promotion_n.png?v10'
  },
  {
    path: "/",
    label: "ฝากเงิน",
    icon: "noto:dollar-banknote",
    color: null,
    image: 'https://auto.tem-max.net/assets/images/ic_deposit_n.png?v10'
  },
  {
    path: "/",
    label: "เข้าเล่น",
    icon: "flat-color-icons:home",
    color: null,
    image: 'https://auto.tem-max.net/assets/images/ic_play_n.gif?v10'
  },
  {
    path: "/",
    label: "ถอนเงิน",
    icon: "noto:money-bag",
    color: null,
    image: 'https://auto.tem-max.net/assets/images/ic_withdraw_n.png?v10'
  },
  {
    path: "/",
    label: "เข้าสู่ระบบ",
    icon: "noto:locked-with-key",
    color: null,
    image: 'https://auto.tem-max.net/webicons/ic_promotion_n.png?v10'
  },
  {
    path: "/",
    label: "สมัครสมาชิก",
    icon: "noto:identification-card",
    color: null,
    image: 'https://auto.tem-max.net/webicons/ic_promotion_n.png?v10'
  },
  // {
  //   path: "/",
  //   label: "ประวัติ",
  //   icon: "flat-color-icons:view-details",
  //   color: null
  // },
  {
    path: "LINE",
    label: "ติดต่อเรา",
    icon: "logos:whatsapp-icon",
    color: null,
    image: 'https://auto.tem-max.net/images/contact.png?v10'
  },
];

export default function LayoutButtomNavigation(props: IAppProps) {
  const path = usePathname();
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [config] = useConfigStore((state) => [state.config], shallow);
  const [user] = useAuthStore((state) => [state.user!], shallow);
  const [setOpenAppModal, setData] = useAppModalStore((state) => [
    state.setOpen,
    state.setData,
  ]);
  const [setOpenLoginModal] = useAuthModalStore(
    (state) => [state.setOpenLoginModal],
    shallow
  );
  const isLoggedIn = user != null;

  React.useEffect(() => {
    const index = bottomNavigationActions.findIndex(
      (action) => action.path === path
    );
    setActiveIndex(index);
  }, [path]);

  return isLoggedIn && (
    <Container
      maxWidth="md"
      sx={{
        p: 0,
        m: 0,
      }}
    >
      <BottomNavigation
        sx={{
          background: (theme) => theme.name === 'preset14'
            ? 'linear-gradient(180deg, #FC9ED3, #d566f0)'
            : theme.gradient[400]
        }}
        showLabels
        value={activeIndex}
        onChange={(event, newValue) => {
          setActiveIndex(newValue);
        }}
      >
        {
          bottomNavigationActions
            .filter(action => {
              const userList = ['แพ็คเกจ', 'ฝากเงิน', 'เข้าเล่น', 'ถอนเงิน', 'ติดต่อเรา']
              const guestList = ['เข้าเล่น', 'เข้าสู่ระบบ', 'สมัครสมาชิก', 'ติดต่อเรา']

              return user
                ? userList.find((menu: any) => menu === action.label)
                : guestList.find((menu: any) => menu === action.label)
            })
            .map((action, index) => (
              <BottomNavigationAction
                component={Link}
                href={
                  action.path == "LINE" ? config?.line_oa_url || "" : action.path
                }
                target={action.path == "LINE" ? "_blank" : "_self"}
                key={index}
                label={action.label}
                sx={{
                  color: (theme) => `${theme.palette.primary.main} !important`,
                  minWidth: 0,
                  '.Mui-selected': {
                    fontSize: '0.75rem !important',
                    color: (theme) => theme.palette.primary.main
                  },
                  ...((action.label === 'เข้าเล่น' && user) && {
                    justifyContent: 'end',
                    top: '-2.5px'
                  })
                }}
                icon={
                  !user ? (
                    <Icon
                      id="icon"
                      icon={action.icon || ''}
                      width={24}
                      height={24}
                    />
                  ) : (
                    <Avatar
                      alt="tooltip"
                      src={action.image}
                      sx={{
                        borderRadius: 0,
                        width: action.label === 'เข้าเล่น' ? '60px' : '35px',
                        height: action.label === 'เข้าเล่น' ? '60px' : '35px',
                      }}
                    />
                  )
                }
                onClick={(e) => {
                  if (action.label === 'หน้าแรก' || action.label === 'ติดต่อเรา' || action.label === 'เข้าเล่น') {
                    return;
                  } else {
                    e.preventDefault()

                    switch (action.label) {
                      case 'ฝากเงิน':
                        setOpenAppModal(true);
                        setData('deposit');
                        return;

                      case 'แพ็คเกจ':
                      case 'โปรโมชั่น':
                        setOpenAppModal(true);
                        setData('promotion');
                        return;

                      case 'ถอนเงิน':
                        setOpenAppModal(true);
                        setData('withdraw');
                        return;


                      case 'เข้าสู่ระบบ':
                        setOpenLoginModal(true);
                        return;


                      case 'สมัครสมาชิก':
                        setOpenAppModal(true);
                        setData('signUp');
                        return;

                      default:
                        return;
                    }
                  }
                }}
              />
            ))
        }
      </BottomNavigation>
    </Container>
  );
}