import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button, alpha, Tabs, Tab, useMediaQuery } from "@mui/material";
import { Icon } from "@iconify/react";
import { useRouter } from "next/navigation";
import { useConfigStore } from "@/stores/configStore";
import { shallow } from "zustand/shallow";
import Image from "next/image";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${alpha(theme.palette.primary.main, 0.4)}`,
  borderRadius: 16,
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.getContrastText(theme.palette.secondary.main),
  "&:hover": {
    color: theme.palette.primary.main,
    background: alpha(theme.palette.primary.main, 0.15),
  },
  ...((theme.name === "preset3" ||
    theme.name === "preset6" ||
    theme.name === "preset12" ||
    theme.name === "preset13" ||
    theme.name === "preset15") && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    "&:hover, &:active, &:focus": {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      background: alpha(theme.palette.primary.main, 0.8),
    },
  }),
  ...(theme.name === "preset5" && {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    "&:hover, &:active, &:focus": {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      background: alpha(theme.palette.primary.main, 0.8),
    },
  }),
  ...((theme.name === "preset17" ||
    theme.name === "preset23" ||
    theme.name === "preset27" ||
    theme.name === "preset34") && {
    background: theme.gradient[610],
    color: theme.palette.getContrastText(theme.palette.primary.main),
    "&:hover, &:active, &:focus": {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      background: alpha(theme.palette.primary.main, 0.8),
    },
  }),
}));

type Menu = {
  key: string;
  label: string;
  icon?: string;
  image?: string;
};

const menus: Menu[] = [
  // {
  //   key: "recommend",
  //   label: "ล็อบบี้",
  //   icon: "emojione-v1:cherries",
  // },
  {
    key: "home",
    label: "ทั้งหมด",
    icon: "emojione-v1:house",
    image: 'https://auto.tem-max.net/assets/images/all_game.png?v10'
  },
  {
    key: "sport",
    label: "กีฬา",
    icon: "emojione-v1:soccer-ball",
    image: 'https://auto.tem-max.net/assets/images/sport_game.png?v10'
  },
  {
    key: "casino",
    label: "คาสิโน",
    icon: "emojione-v1:joker",
    image: 'https://auto.tem-max.net/assets/images/casino_game.png?v10'
  },
  {
    key: "slot",
    label: "สล็อต",
    icon: "emojione-v1:slot-machine",
    image: 'https://auto.tem-max.net/assets/images/slot_game.png?v10'
  },
  {
    key: "card",
    label: "เกมไพ่",
    icon: "emojione-v1:game-die",
    image: 'https://auto.tem-max.net/assets/images/poker_game_bk.png?v10'
  },
  {
    key: "lottery",
    label: "หวย",
    icon: "emojione-v1:pool-8-ball",
    image: 'https://auto.tem-max.net/assets/images/lotto_game.png?v10'
  },
  // {
  //   key: "fish",
  //   label: "ยิงปลา",
  //   icon: "logos:glitch-icon",
  // },
];

export default function GameMenu(props: any) {
  const theme = useTheme();
  const [config] = useConfigStore((state) => [state.config], shallow);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMD = useMediaQuery(theme.breakpoints.down("md"));
  const router = useRouter();

  const handleMenuClick = (_value: any, index: number) => {
    // console.log(index)

    const localStoragePrefix = "category";
    localStorage.setItem(localStoragePrefix, `${index}`);
    props.setCategory(index);

    // router.push(`/?category=${key}`);
  };

  const filterMenus = config?.agent_name === 'wisdom168'
    ? menus
    : menus

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Item
            sx={{
              display: "flex",
              justifyContent: {
                xs: "center",
                md: "start",
                lg: "start",
              },
            }}
          >
            <Tabs
              orientation={isMD ? 'horizontal' : 'vertical'}
              value={props.category}
              onChange={handleMenuClick}
              variant="scrollable"
              aria-label="scrollable auto tabs example"
              indicatorColor={"none" as any}
              scrollButtons={false}
              // scrollButtons="auto"
              sx={{
                ...(!isMD && {
                  width: '100%;',
                })
                // height: '48px',
                // minHeight: '48px'
              }}
            >
              {filterMenus.map((menu, index) => (
                <Tab
                  icon={
                    // <Icon
                    //   id="icon"
                    //   icon={menu?.icon || ""}
                    //   width={20}
                    //   height={20}
                    // />

                    <Image
                      src={`${menu?.image}` || ""}
                      width={45}
                      height={45}
                      alt={menu?.image || ""}
                    />
                  }
                  iconPosition={isMD ? "top" : "start"}
                  key={menu.key}
                  label={menu.label}
                  sx={{
                    fontSize: '0.75rem',
                    p: 0,
                    py: 1,
                    my: 0.5,
                    minHeight: '54px',
                    color: theme.palette.primary.main,
                    background: props.category === index
                      ? '#00000080'
                      : !isMD
                        ? '#00000080'
                        : 'transparent',
                    // boxShadow: props.category === index
                    //   ? '0px 2px 4px 0px #fff inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px -2px 1px 0px rgba(0, 0, 0, 0.25) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
                    //   : '0px 2px 4px 0px #2B2B2B inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px -2px 1px 0px rgba(0, 0, 0, 0.25) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                    borderRadius: '10px',
                    ...(isMobile && {
                      minWidth: "60px",
                    }),
                    ...(!isMD && ({
                      mt: index !== 0 ? 1.5 : 0,
                      mb: index + 1 === filterMenus.length ? '10px' : 0,
                      justifyContent: 'start',
                      padding: '0 8px',
                    }))
                  }}
                />
              ))}
            </Tabs>
          </Item>
        </Grid>
      </Grid>
    </Box >
  );
}
