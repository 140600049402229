"use client";

import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Stack, Theme, useTheme } from "@mui/material";
import { alpha } from "@mui/system";
import styled from "@emotion/styled";
import { Logo } from "../logo";
import { Announcement } from "@/@types/announcement";
import { AnnouncementCarousel } from "../carousel";
import { useConfig } from "@/hooks";
import usePromotion from "@/hooks/usePromotion";
import { useSnackbar } from "notistack";
import { useAuthStore } from "@/stores/authStore";
import { useAuthModalStore } from "@/stores/authModalStore";
import { shallow } from "zustand/shallow";
import CloseButton from "./CloseButton";
import { useConfigStore } from "@/stores/configStore";

type Props = {
  items: Announcement[];
  open: boolean;
  close: () => void;
};

export default function AnnouncementModal({ items, open, close }: Props) {
  const theme = useTheme();
  const [config] = useConfigStore((state) => [state.config], shallow);
  const { enqueueSnackbar: toast } = useSnackbar();
  const [setOpenLoginModal] = useAuthModalStore(
    (state) => [state.setOpenLoginModal],
    shallow
  );

  const { user } = useAuthStore(
    (state) => ({
      user: state.user,
    }),
    shallow
  );
  const { accept } = usePromotion();
  const { logo } = useConfig();

  const handleGettingPromotion = (id: string | number) => {
    if (!user) {
      close();
      setOpenLoginModal(true);
      return toast("กรุณาเข้าสู่ระบบก่อนกดรับโปรโมชั่น", { variant: "error" });
    }
    accept({
      id,
      onSuccess: (message: string) => {
        toast(message, { variant: "success" });
      },
      onError: (message: string) => {
        toast(message, { variant: "error" });
      },
    });
  };

  return user && (
    <Modal
      disableEnforceFocus
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: alpha(theme.palette.secondary.main, 0.7),
            backdropFilter: "blur(18px)",
          },
        },
      }}
    >
      <Content>
        <CloseButton onClick={close} />
        <Stack alignItems={"center"}>
          <Logo src={config?.logo || ""} height={theme.config.logo.height} />
        </Stack>
        <AnnouncementCarousel
          items={items}
          onClickGettingPromotion={handleGettingPromotion}
        />
      </Content>
    </Modal>
  );
}

const Content = styled(Box)<{ theme?: Theme }>(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  [theme.breakpoints.up("md")]: {
    width: "800px",
  },
  [theme.breakpoints.up("sm")]: {
    width: "450px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
  height: "auto",
  maxHeight: "80%",
  overflow: "scroll",
  background: theme.gradient[300],
  boxShadow: theme.shadows[6],
  borderRadius: theme.shape.borderRadius,
  border: `2px solid ${alpha(theme.palette.primary.main, 0.05)}`,
  padding: theme.spacing(2),
}));
