"use client";

import { usePlayingPopup } from "@/hooks";
import {
  Affiliate,
  AffilateMonthly,
  Announcement,
  Alert,
  ChangePassword,
  Deposit,
  DepositQR,
  ForgotPassword,
  Histories,
  LuckyWheel,
  WheelWinlose,
  Mission,
  MysteryBox,
  PromotionsList,
  Refund,
  Register,
  UserProfile,
  Withdraw,
  CampaignList,
  Cashback
} from "@/sections";
import PlayingPopup from "@/sections/PlayingPopup";
import TransferWallet from "@/sections/TransferWallet";
import Wallets from "@/sections/Wallets";
import { useAppModalStore } from "@/stores/appModalStore";
import { useConfigStore } from "@/stores/configStore";
import styled from "@emotion/styled";
import { Stack, Theme, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/system";
import { shallow } from "zustand/shallow";
import { Logo } from "../logo";
import CloseButton from "./CloseButton";

type ContentKey =
  | "deposit"
  | "withdraw"
  | "signUp"
  | "Profile"
  | "changePassword"
  | "forgotPassword"
  | "historyAll"
  | "wheel"
  | "mysteryBox"
  | "refund"
  | "coupon"
  | "promotion"
  | "announcement"
  | "affiliate"
  | "playingPopup"
  | "wallets"
  | "transferWallet"
  | "campaign"
  | "alert"
  | "cashbackTurnover"
  | "cashbackWinlose"

const Content = styled(Box)<{ theme?: Theme }>(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  [theme.breakpoints.up("md")]: {
    width: "800px",
  },
  [theme.breakpoints.up("sm")]: {
    width: "450px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
  height: "auto",
  maxHeight: "80%",
  overflow: "auto",
  background: theme.gradient[300],
  boxShadow: theme.shadows[6],
  borderRadius: theme.shape.borderRadius,
  border: `2px solid ${alpha(theme.palette.primary.main, 0.05)}`,
  padding: theme.spacing(2),
}));

export default function AppModal() {
  const theme = useTheme();
  const { isPlayingPopup } = usePlayingPopup();
  const [config] = useConfigStore((state) => [state.config], shallow);
  const [open, setOpen, contentKey, title] = useAppModalStore(
    (state) => [state.open, state.setOpen, state.contentKey, state.title],
    shallow
  );

  const isShowCloseButton = !(isPlayingPopup && contentKey == "playingPopup");

  const handleClose = () => {
    if (isPlayingPopup && contentKey == "playingPopup") return;
    setOpen(false);
  };

  const depositQRList = ['slow88', 'thx777', 'luckygirl678', 'puppybet', 'enjoykrub', 'thegroup168', 'pokkao', 'yedped123', 'lek77', 'ubetpg', 'hengmak168', 'wisdom168', 'pg1play', 'm150']
  const hasDepositQRList = config?.deposit_type
    ? config?.deposit_type !== 'bankaccount'
      ? true
      : false
    : depositQRList.find((agent: any) => agent === config?.agent_name)
  const hasCashbackList = config?.cashback_type && config?.cashback_type !== 'profit'
  const hasWheelList = config?.wheel_type
  const hasAffiliateList = ['dragonhunte8'].find((agent: any) => agent === config?.agent_name)

  const contentComponents: Record<ContentKey, JSX.Element> = {
    deposit: hasDepositQRList ? <DepositQR /> : <Deposit />,
    refund: hasCashbackList ? <Cashback /> : <Refund />,
    wheel: hasWheelList ? <WheelWinlose /> : <LuckyWheel />,
    withdraw: <Withdraw />,
    signUp: <Register />,
    Profile: <UserProfile />,
    changePassword: <ChangePassword />,
    forgotPassword: <ForgotPassword />,
    historyAll: <Histories />,
    mysteryBox: <MysteryBox />,
    promotion: <PromotionsList />,
    coupon: <Mission />,
    affiliate: hasAffiliateList ? <AffilateMonthly /> : <Affiliate />,
    announcement: <Announcement />,
    transferWallet: <TransferWallet />,
    wallets: <Wallets />,
    playingPopup: <PlayingPopup />,
    campaign: <CampaignList />,
    alert: <Alert />,
    cashbackTurnover: <Cashback cashback_type={"turnover"} />,
    cashbackWinlose: <Cashback cashback_type={"winlose"} />,
  };

  return contentKey !== "alert" ? (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: alpha(theme.palette.secondary.main, 0.7),
              backdropFilter: "blur(18px)",
            },
          },
        }}
      >
        <Content
          sx={{
            ...(contentKey === "wallets" && {
              padding: "0 !important",
            }),
          }}
        >
          {isShowCloseButton && <CloseButton onClick={handleClose} />}
          {contentKey !== "wallets" && (
            <Stack alignItems={"center"}>
              <Logo
                src={config?.logo || ""}
                height={theme.config.logo.height}
              />
              <Typography mt={2} variant="h5" textAlign={"center"}>
                {title}
              </Typography>
              {contentComponents[contentKey as ContentKey]}
            </Stack>
          )}
          {contentKey === "wallets" &&
            contentComponents[contentKey as ContentKey]}
        </Content>
      </Modal>
    </div>
  ) : (
    <div>
      {contentKey === "alert" &&
        contentComponents[contentKey as ContentKey]}
    </div >
  )
}
