"use client";

import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  // TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";

import { LoadingButton } from "@mui/lab";

import { useAppModalStore } from "@/stores/appModalStore";
import { useAuthModalStore } from "@/stores/authModalStore";
import { useAuthStore } from "@/stores/authStore";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRouter } from "next/navigation";
import { useSnackbar } from "notistack";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { shallow } from "zustand/shallow";
import { Logo } from "@/components/logo";
import { useConfigStore } from "@/stores/configStore";
import { Icon } from "@iconify/react";
import { useState } from "react";
import { Person, Phone, Key } from "@mui/icons-material";

type Inputs = {
  username: string;
  password: string;
};

const schema = yup.object().shape({
  username: yup
    .string()
    .matches(/^[0-9]+$/, "เบอร์โทรศัพท์ต้องเป็นตัวเลขเท่านั้น")
    .min(6, "เบอร์โทรศัพท์ต้องมีอย่างน้อย 10 ตัวอักษร")
    .max(10, "เบอร์โทรศัพท์ต้องมีไม่เกิน 10 ตัวอักษร")
    .required("กรุณากรอกเบอร์โทรศัพท์"),
  password: yup
    .string()
    .min(6, "รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร")
    .max(30, "รหัสผ่านต้องมีไม่เกิน 30 ตัวอักษร")
    .required("กรุณากรอกรหัสผ่าน"),
});

type Props = {
  onFinish?: () => void;
};

import MuiTextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

const TextField = styled(MuiTextField)(({ theme }) => ({
  // '& .MuiOutlinedInput-root': {
  // },
  // '& .MuiInputAdornment-root': {
  //   background: 'linear-gradient(160deg, #b47e11, #fec33f, #e09c14);',
  //   border: '1px solid #fec33f !important;',
  // },
}));

export default function Login({ onFinish = () => { } }: Props) {
  const theme = useTheme();
  const route = useRouter();
  const isMD = useMediaQuery(theme.breakpoints.up("md"));
  const [config] = useConfigStore((state) => [state.config], shallow);
  const { enqueueSnackbar } = useSnackbar();
  const [setOpenAppModal, setAppDataModal] = useAppModalStore(
    (state) => [state.setOpen, state.setData],
    shallow
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({ resolver: yupResolver(schema) });

  const [setOpenLoginModal] = useAuthModalStore(
    (state) => [state.setOpenLoginModal],
    shallow
  );

  const [login, loading, setLoading, setUser, isLoggedIn] = useAuthStore(
    (state) => [state.login, state.loading, state.setLoading, state.setUser, state.user!],
    shallow
  );

  const [showPassword, setShowPassword] = useState(false)

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    setLoading(true);
    const { username, password } = data;
    login(username, password)
      .then((res: any) => {
        if (res?.errMessage) {
          enqueueSnackbar(res.errMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } else {
          route.push("/");
          // enqueueSnackbar("เข้าสู่ระบบสำเร็จ", {
          //   variant: "success",
          //   anchorOrigin: {
          //     vertical: "top",
          //     horizontal: "right",
          //   },
          // });
          onFinish();
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar("เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      });
  };

  const handleClickForgot = () => {
    setOpenLoginModal(false);
    setAppDataModal("forgotPassword");
    setOpenAppModal(true);
  };

  if (
    isLoggedIn
  ) {
    route.push("/");
  }

  return (
    <Paper
      elevation={1}
      sx={{
        opacity: 'unset;',
        pointerEvents: 'unset;',
        marginLeft: 'auto !important;',
        marginRight: 'auto !important;',
        flex: '0 0 auto;',
        ...(isMD && {
          width: '40%;'
        }),
        ...(theme.name === 'preset29' && {
          background: 'linear-gradient(rgb(215, 4, 4), rgb(24, 23, 55))'
        })
      }}
    >
      <Box sx={{
        position: config?.agent_name !== 'temmed' ? 'fixed' : 'absolute',
        top: 0,
        right: 0,
        padding: '.3rem 1.5rem .5rem'
      }}>
        <IconButton
          style={{
            padding: '0 3px'
          }}
          aria-label="clear"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={() => {
          }}
          color="inherit"
        >
          <Icon
            id="icon"
            icon={"emojione:flag-for-thailand"}
            width={18}
            height={18}
            style={{ marginTop: '-2px' }}
          />
        </IconButton>
        <Typography
          variant="caption"
          mb={-1.2}
          color="white"
          sx={{ fontSize: '0.875rem', marginLeft: '3px' }}
        >
          {`Thai`}
        </Typography>
      </Box>
      <Box p={2} mt={3}>
        <Box
          mb={3}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Logo
            src={
              config?.agent_name === 'temmed'
                ? `https://images-platform.sgp1.cdn.digitaloceanspaces.com/logo/LOGO%20TEM-MED.jpg`
                : config?.logo || ""
            }
            height={
              config?.agent_name === 'temmed'
                ? 400
                : 256
            }
            isLink={false}
          />
        </Box>
        <Box mt={2}>
          <Box p={2} component={"form"} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <Controller
                    name="username"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        id="username"
                        // autoFocus
                        // label="ชื่อเข้าใช้งาน"
                        sx={{
                          ...(errors.username && {
                            borderColor: (theme) => theme.gradient[900] || "error.main",
                            borderStyle: "solid",
                            borderWidth: 1,
                            borderRadius: "16px",
                            // backgroundColor: '#fff',
                            "& .MuiInputLabel-root": {
                              color: (theme) => theme.gradient[900] || "error.main",
                            }
                          })
                        }}
                        value={value}
                        onBlur={onBlur}
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(/\D/g, "");
                          onChange(numericValue);
                        }}
                        error={Boolean(errors.username)}
                        placeholder="เบอร์โทร"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton
                                style={{
                                  // border: "1.5px solid white",
                                  height: "28px",
                                  width: "28px",
                                  // color: '#fec33f'
                                }}
                                aria-label="clear"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                color="inherit"
                              >
                                <Person style={{ fontSize: "18px" }} />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    )}
                  />
                  {errors.username && (
                    <FormHelperText
                      sx={{
                        ...(errors.password && {
                          borderColor: (theme) => theme.gradient[900] || "error.main",
                        })
                      }}>
                      {errors.username.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <Controller
                    name="password"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        id="password"
                        // label="รหัสผ่าน"
                        sx={{
                          ...(errors.password && {
                            borderColor: (theme) => theme.gradient[900] || "error.main",
                            borderStyle: "solid",
                            borderWidth: 1,
                            borderRadius: "16px",
                            "& .MuiInputLabel-root": {
                              color: (theme) => theme.gradient[900] || "error.main",
                            }
                          })
                        }}
                        type={showPassword ? "text" : "password"}
                        // value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        error={Boolean(errors.password)}
                        placeholder="รหัสผ่าน"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton
                                style={{
                                  // border: "1.5px solid white",
                                  height: "28px",
                                  width: "28px",
                                  // color: '#fec33f'
                                }}
                                aria-label="clear"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                color="inherit"
                              >
                                <Key style={{ fontSize: "18px" }} />
                              </IconButton>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                style={{
                                  // color: '#fec33f'
                                }}
                                aria-label="clear"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={() => {
                                  setShowPassword((prevState) => !prevState)
                                }}
                                color="inherit"
                              >
                                <Icon
                                  id="icon"
                                  icon={showPassword ? "mdi:eye-off-outline" : "mdi:eye-outline"}
                                  width={24}
                                  height={24}
                                />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    )}
                  />
                  {errors.password && (
                    <FormHelperText
                      sx={{
                        ...(errors.password && {
                          borderColor: (theme) => theme.gradient[900] || "error.main",
                        })
                      }}>
                      {errors.password.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                {/* <FormControlLabel control={<Checkbox defaultChecked />} label="จำรหัสผ่าน" /> */}
                <Stack direction="row" justifyContent="end">
                  {/* <FormControlLabel control={<Checkbox />} label="จำรหัสผ่าน" sx={{ color: (theme) => theme.gradient[900] }} /> */}
                  <FormControlLabel
                    onClick={() => handleClickForgot()}
                    control={<Typography></Typography>}
                    label="ลืมรหัสผ่าน ?" sx={{ color: (theme) => theme.gradient[900] }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={12}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  fullWidth
                  loading={loading}
                  sx={{
                    border: '1px solid #d3d3d3;',
                    color: '#fec33f',
                    height: '40px',
                    outline: 'none;',
                    textAlign: 'center;',
                    transition: 'all .5s;',
                    borderRadius: '50px;',
                    background: 'transparent;',
                    "&:hover": {
                      background: 'linear-gradient(160deg, #b47e11, #fec33f, #e09c14);',
                      color: '#000'
                    },

                    // color: (theme) => theme.name === 'preset14' ? '#fff' : theme.gradient[900],
                    // backgroundColor: (theme) => theme.name === 'preset14' ? '#ffc100' : 'default',
                    // height: "50px",
                    // "&:hover": {
                    //   color: (theme) => theme.name === 'preset14' ? '#fff' : 'default',
                    //   backgroundColor: (theme) => theme.name === 'preset14' ? '#ffc100' : 'default',
                    // },
                  }}
                >
                  เข้าสู่ระบบ
                </LoadingButton>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box mb={0}>
                  <Divider>
                    <Typography variant="subtitle1" align="center">
                      หรือยังไม่มีบัญชี
                    </Typography>
                  </Divider>
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <LoadingButton
                  onClick={e => {
                    e.preventDefault()
                    route.push("/register");
                  }}
                  variant="contained"
                  fullWidth
                  sx={{
                    border: '2px solid #fff2ba87 !important;',
                    color: '#fff',
                    height: '40px',
                    outline: 'none;',
                    textAlign: 'center;',
                    transition: 'all .5s;',
                    borderRadius: '50px;',
                    background: 'linear-gradient(0deg,#a25700,#fc0)!important',
                  }}
                >
                  สมัครสมาชิก
                </LoadingButton>
              </Grid>
              <Grid item xs={12} md={12}>
                <LoadingButton
                  onClick={e => {
                    e.preventDefault()
                    const newWindow = window.open(config?.line_oa_url, '_blank', 'noopener,noreferrer')
                    if (newWindow) newWindow.opener = null

                    // route.push("/register");
                  }}
                  variant="contained"
                  fullWidth
                  sx={{
                    border: '2px solid #fff2ba87 !important;',
                    color: '#fff',
                    height: '40px',
                    outline: 'none;',
                    textAlign: 'center;',
                    transition: 'all .5s;',
                    borderRadius: '50px;',
                    background: 'linear-gradient(#2fbf30,#043804)!important',
                  }}
                >
                  ติดต่อเรา
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Paper >
  );
}
