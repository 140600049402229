"use client";

import { useEffect, useState } from "react";
import useProvidersList from "@/hooks/useProvidersList";
import { useConfigStore } from "@/stores/configStore";
import { shallow } from "zustand/shallow";
import { Box, CircularProgress, Divider, Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import GameMenu from "./GameMenu";
import DepositAndWithdrawButtonGroup from "./DepositAndWithdrawButtonGroup";
import MainMenu from "./MainMenu";
import React from "react";

type Game = {
  category: string;
  name: string;
  code: string;
  status: boolean;
  display: boolean;
  image: string;
  image_newlobby: string;
  image_modify: string;
  is_lobby: boolean;
  games_lobby: any[];
};

const CATEGORY: {
  [key: string]: string;
} = {
  slot: "🎰 ผู้ให้บริการเกมสล็อต",
  casino: "🎲 ผู้ให้บริการเกมคาสิโนสด",
  card: "🃏 ผู้ให้บริการเกมไพ่",
  sport: "⚽ ผู้ให้บริการเกมกีฬา",
};

const orderProvider: {
  [key: string]: any[];
} = {
  0: [
    'YGR',
    'HACKSAW',
    'FAC',
    'NEXTSPIN',
    'NOLIMIT',
    'ASKMEBET',
    'ASKMEPLAY',
    "SG",
    'PTG',
    'DG',
    'AGGAME',
    'SA',
    'AE',
    "JL",
    "JK",
    "PP",
    "PG",
  ],
  1: [
    'SBO',
    'SABASPORTS',
    'UMBET',
    'AMBSPORTBOOK',
    'AFB1188',
    'PARLAYBAY',
    'LALIKA',
    'COCKFIGHT',
    'VIRTUAL_SPORT'
  ],
  2: [
    'SEXY',
    'SAGAME',
    'AGGAME',
    'DREAM',
    'WM',
    'PRETTY',
    'ALLBET',
    'PRAGMATIC_LIVECASINO',
    'BETGAME',
    'WECASINO',
    'AFB1188_LIVECASINO',
    'MICRO_LIVECASINO',
    'BIGGAME',
    'YEEBET',
    'CQ9_LIVECASINO'
  ],
  3: [
    'PGSOFT2',
    'SPADE',
    'SLOTXO',
    'JOKER',
    'ASKMESLOT',
    'PRAGMATIC_SLOT',
    'ASKMEPLAY',
    'ASKMEBET',
    'NOLIMIT',
    'NEXTSPIN',
    'FACHAI',
    'HACKSAW',
    'YGR',
    'MANNA',
    'KAGAME',
    'CQ9',
    'PLAYNGO',
    'AMEBA',
    'AMBGAMING',
    'MICRO',
    'HABANERO',
    'ROYAL',
    'RELAX',
    'YGGDRASIL',
    'ADVANT',
    'REDTIGER',
    '918KISS',
    '759GAMING',
    '5G_GAMES',
    'BOOONGO',
    'FUNKY',
    'SIMPLEPLAY',
    'EVOPLAY',
    'RICH88',
    'BOOMING',
    'SEXY_SLOT',
    'UPG',
    'WAZDAN',
    'NINJA',
    'I8',
    'OCTOPLAY',
    'NETENT',
    'LIVE22',
    'DRAGONGAMING',
    'GOLDY',
    'ACE333',
    'WMSLOT',
    'AFB1188_SLOT',
    'GIOCO',
    'FUNTA'
  ],
  4: [
    'KINGMAKER',
    'AMBPOKER',
    'MIKIWORLD'
  ],
  5: [
  ],
}

export default function ProvidersList() {
  const {
    groupedArray,
    isGameListLoading,
    theme,
    handleProviderClick,
    handleRightMenuClick,
    isLoggedIn,
    width,
    height,
    ItemImage,
    isActive,
    activePromotionPlatform,
  } = useProvidersList();
  const [category, setCategory] = useState(0)
  const [config] = useConfigStore((state) => [state.config], shallow);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMD = useMediaQuery(theme.breakpoints.down("md"));
  const customAgentList = ['tiga98', 'funee8', 'ehub8', 'rvp8', 'v7heng', 'gax88', 'bcv789', 'kubwin', 'hengmak168']
  const isCustomAgentPreset = customAgentList.find(agent => agent === config?.agent_name)

  useEffect(() => {
    const localStoragePrefix = "category";
    const category = localStorage.getItem(localStoragePrefix);

    // if (category) {
    //   setCategory(parseInt(category, 10));
    // }
  }, []);

  const [scrollY, setScrollY] = React.useState({
    position: 0,
    direction: 'idle'
  });
  const onScroll = React.useCallback((event: any) => {
    // const { pageYOffset, scrollY } = window;
    // console.log("yOffset", pageYOffset, "scrollY", scrollY);
    const { scrollY } = window;
    setScrollY((prevState) => {
      return {
        position: scrollY,
        direction: prevState.position < scrollY ? 'down' : 'up'
      }
    });
  }, []);

  React.useEffect(() => {
    //add eventlistener to window
    window.addEventListener("scroll", onScroll, { passive: true });
    // remove event on unmount to prevent a memory leak with the cleanup
    return () => {
      window.removeEventListener("scroll", onScroll, { passive: true } as any);
    }
  }, []);

  if (isGameListLoading) {
    return (
      <>
        <Grid sx={{ textAlign: "center" }}>
          <Box sx={{ mt: [16, 16, 18] }}>
            <CircularProgress></CircularProgress>
          </Box>
        </Grid>
      </>
    );
  } else {
    const customArray: any = [{
      category: 'home',
      data: []
        .concat(groupedArray[0] ? groupedArray[0]?.data as any : [])
        .concat(groupedArray[1] ? groupedArray[1]?.data as any : [])
        .concat(groupedArray[2] ? groupedArray[2]?.data as any : [])
        .concat(groupedArray[3] ? groupedArray[3]?.data as any : [])
        .concat(groupedArray[4] ? groupedArray[4]?.data as any : [])
    }]
      .concat(groupedArray as any)

    return (
      <Box sx={{
        flexGrow: 1,
        mt: [16, 16, 18]
      }}>
        <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            md={2.5}
            sx={{
              pt: 2.5,
              pb: 0,
            }}
          >
            <Box
              sx={{
                ...(isMD) && {
                  position: 'fixed',
                  top: {
                    'xs': '75px',
                    'md': 0
                  },
                  left: 0,
                  background: theme.gradient[400],
                  width: '100%',
                  zIndex: 1200,
                  borderTop: '1px solid #FFE2AE;'
                }
              }}
            >
              <GameMenu category={category} setCategory={setCategory} />
            </Box>
          </Grid>

          {/* {
            isLoggedIn && (
              <>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{ pt: 3, pb: 0 }}
                >
                  <MainMenu />
                </Grid>
                {
                  isMobile && (
                    <Grid
                      item
                      xs={12}
                      md={12}
                      sx={{ pt: 3, pb: 0 }}
                    >
                      <DepositAndWithdrawButtonGroup />
                    </Grid>
                  )
                }
              </>
            )
          } */}

          {
            customArray
              .filter((item: any) => {
                const match = {
                  0: 'home',
                  1: 'sport',
                  2: 'casino',
                  3: 'slot',
                  4: 'card',
                  5: 'lottery'
                } as any

                return item.category === match[category]
              })
              .map((item: any, index: any) => (
                <Grid
                  id={`category-${item.category}`}
                  key={`category-${item.category}`}
                  item
                  xs={12}
                  md={9.5}
                  sx={{ pt: isMD ? 6 : 2, pb: 0 }}
                >
                  {/* {
                      category !== 0 && (
                        <Box sx={{
                          flexGrow: 1,
                          pl: 1,
                          pb: 1,
                        }}
                        >
                          <Typography
                            variant="body1"
                            sx={{
                              px: 1,
                              // fontSize: '1rem',
                              color: (theme) => "white",
                              background: 'linear-gradient(90deg, rgba(14, 14, 14, 0.60) 0%, #AB82C6 4.41%, rgba(14, 14, 14, 0.60) 83.01%);'
                            }}
                          >
                            {CATEGORY?.[item.category] || "~"}
                          </Typography>
                        </Box>
                      )
                    } */}
                  <Box
                    mt={-0.5}
                    borderRadius={8}
                    width={"6px"}
                    height={"4px"}
                  // bgcolor={category === 0 ? theme.palette.primary.main : 'none'}
                  />
                  <Grid
                    container
                    columns={{
                      xs: 12,
                      sm: 12,
                      md: 6
                    }}
                    spacing={1}
                    justifyContent={"space-start"}
                    alignItems={"center"}
                    sx={{
                      padding: 1
                    }}
                  >
                    {item.data
                      .sort((a: any, b: any) => {
                        return category == 0
                          ? orderProvider[category].indexOf(b.code) - orderProvider[category].indexOf(a.code)
                          : orderProvider[category].indexOf(a.code) - orderProvider[category].indexOf(b.code)
                      })
                      .map((game: any) => (
                        <Grid
                          key={`${game?.code}_${game?.name}`}
                          item
                          xs={3}
                          sm={3}
                          md={1}
                          position="relative"
                        >
                          <Box
                            onClick={
                              !isLoggedIn
                                ? () => {
                                  handleRightMenuClick();
                                }
                                : undefined
                            }
                          >
                            <Box
                              style={{
                                textDecoration: "none",
                                color: theme.palette.primary.main,
                                textAlign: "center",
                                padding: isMD ? '4px' : '12px'
                              }}
                              onClick={() => {
                                if (isLoggedIn) {
                                  handleProviderClick(
                                    game?.games_lobby?.[0]?.id || "",
                                    game?.code || "",
                                    game?.category || "",
                                    game?.games_lobby?.[0]?.code || "",
                                    game?.games_lobby?.[0]?.is_iframe ?? true,
                                    game?.code_betflik || ""
                                  );
                                }
                              }}
                            >
                              {/* <ItemImage
                                placeholder={"blur"}
                                blurDataURL="/assets/icons/category-casino.png"
                                src={'https://auto.tem-max.net/assets/images/f2.gif'}
                                width={160}
                                height={160}
                                alt={game.name}
                                sx={{
                                  mixBlendMode: 'plus-lighter;',
                                  zIndex: '10;',
                                  position: 'absolute;',
                                  padding: '0 16px 16px 0;',
                                  borderRadius: '24px;',
                                  // borderRadius: '12px;',
                                  // maxWidth: '108.96px',
                                  // maxHeight: '108.96px',
                                }}
                                style={{
                                  width: "100%",
                                  height: "auto"
                                }}
                              /> */}

                              <ItemImage
                                placeholder={"blur"}
                                blurDataURL="/assets/icons/category-casino.png"
                                src={
                                  [
                                    'YGGDRASIL', 'ASKMEPLAY', 'JOKER', 'Joker', 'WECASINO', 'SAGAME', 'ALLBET',
                                    'UMBET', 'COCKFIGHT', 'AMBSPORTBOOK', 'SBO', 'VIRTUAL_SPORT', 'SABASPORTS',
                                    'PARLAYBAY', 'KAGAME', 'LALIKA', 'Jili', 'Kalamba Games', 'Fantasma Games',
                                    'Push Gaming', 'Fastspin', 'SA Gaming', 'SV388', 'LUDO', 'HOTRoad', 'HuayDragon',
                                    'VRLOTTO', 'SABA Sports', 'E1sport', 'Horsebook', 'IM Sports', 'UFA'
                                  ].includes(game?.name)
                                    ? `https://images-platform.sgp1.cdn.digitaloceanspaces.com/gamepartners/AMB_STG/${game?.name}.png`
                                    : `https://images-platform.sgp1.cdn.digitaloceanspaces.com/gamepartners/AMB_STG/${game?.name}.webp`
                                }
                                width={160}
                                height={160}
                                alt={game?.name}
                                sx={{
                                  maxWidth: '108.96px',
                                  maxHeight: '108.96px',
                                  border: '2px solid #fdcd00;',
                                  background: 'linear-gradient(180deg, #000, #212121);',
                                  borderRadius: '12px;',
                                  boxShadow: '0px 2px 4px 0px #2B2B2B inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px -2px 1px 0px rgba(0, 0, 0, 0.25) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);',
                                  padding: '6px'
                                  // border: '2px solid #AB82C6;',
                                }}
                                style={{
                                  width: "100%",
                                  height: "auto",
                                  filter:
                                    isLoggedIn &&
                                      isActive &&
                                      !activePromotionPlatform.includes(
                                        game?.code || ""
                                      )
                                      ? "grayscale(90%)"
                                      : "grayscale(0%)",
                                }}
                              />
                              {/* <Typography fontSize={'0.7rem'} sx={{ maxWidth: 'auto', overflowWrap: 'break-word' }}>
                                  {
                                    game.name
                                      .replace('_SLOT', '')
                                      .replace('_LIVECASINO', '')
                                      .replace('_SPORT', '')
                                      .replace('AMBSPORTBOOK', 'AMB')
                                  }
                                </Typography> */}
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              ))
          }
        </Grid>
      </Box>
    );
  }
}
