"use client";

import { usePlayingPopup } from "@/hooks";
import gamelist from "@/hooks/fetchers/useGame";
import usePromotionHistory from "@/hooks/fetchers/usePromotionHistory";
import useLaunch from "@/hooks/useLaunch";
import useTransferWallet from "@/hooks/useTransferWallet";
import useProvidersList from "@/hooks/useProvidersList";
import { useAppModalStore } from "@/stores/appModalStore";
import { useAuthStore } from "@/stores/authStore";
import {
  Box,
  Card,
  CardMedia,
  CardMediaProps,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useParams, useRouter } from "next/navigation";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { shallow } from "zustand/shallow";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const ItemImage = styled(CardMedia)<CardMediaProps>(({ theme }) => ({
  borderRadius: "16px",
  transition: "all 0.12s ease-in-out",
  "&:hover": {
    transform: "scale(1.08)",
    cursor: "pointer",
  },
}));

export default function GamesList() {
  const { theme } = useProvidersList();
  const { launchGame } = useLaunch();
  // const { handleTransfer } = useTransferWallet();
  const { isPlayingPopup } = usePlayingPopup();
  const [setOpen, setData] = useAppModalStore(
    (state) => [state.setOpen, state.setData],
    shallow
  );
  const router = useRouter();
  const params: any = useParams();
  const { data: gameList, isLoading: isGameListLoading } = gamelist(
    params.slug
  );

  const { data: promotion } = usePromotionHistory();
  const [isLoggedIn] = useAuthStore(
    (state) => [state.user!, state.logout],
    shallow
  );

  const activePromotionPlatform =
    promotion?.promotion?.platform?.split(",") || [];

  if (
    ![...activePromotionPlatform].includes(params.slug || "") &&
    isLoggedIn &&
    promotion
  ) {
    router.push("/");
  }

  useEffect(() => {
    if (isPlayingPopup) {
      setData("playingPopup");
      setOpen(true);
    }
  }, [isPlayingPopup]);

  if (isGameListLoading) {
    return (
      <>
        <Grid sx={{ textAlign: "center" }}>
          <Box sx={{ mt: [16, 16, 18] }}>
            <CircularProgress></CircularProgress>
          </Box>
        </Grid>
      </>
    );
  } else {
    const blackList = [
      'Three Monkeys',
      'Baccarat Deluxe',
      'Wizdom Wonders',
      'Farm Invaders'
    ]

    const customList = params?.slug.toUpperCase() === 'PG'
      ? gameList?.filter(game => !blackList.find((item) => item === game.name))
      : gameList

    return (
      <Box sx={{
        flexGrow: 1,
        mt: [16, 16, 18]
      }}>
        <Grid container spacing={0}>
          {(
            !["preset13", "preset14", "preset18", "preset19", "preset20", "preset21", "preset23", "preset24", "preset25", "preset26", "preset27", "preset17"].find(name => name === theme.name)
          ) && (
              <Grid item xs={12} sx={{ pt: 1 }}>
                <Typography variant="h6" align="right">
                  {params.slug}
                </Typography>
              </Grid>
            )}
          {customList?.map((game: any, key: number) => {
            if (!game?.img_custom) {
              return (
                <Grid key={key} item md={2} xs={3}>
                  <Item>
                    <Card>
                      <ItemImage
                        component="img"
                        image={
                          params?.slug.toUpperCase() !== 'PG'
                            ? game?.img_custom ? game?.img_custom : game?.img
                            : `https://images-platform.sgp1.cdn.digitaloceanspaces.com/gamepartners/PG2/${game?.match_name.replaceAll('#', '')}.webp`
                        }
                        title={game?.name}
                        onClick={() => {
                          if (game?.agent_name === "BETFLIK") {
                            launchGame(
                              game?.id || "",
                              isMobile,
                              game?.is_iframe
                            );
                          } else {
                            launchGame(game?.id || "", isMobile);
                          }
                        }}
                      />
                    </Card>
                    <Typography
                      sx={{
                        mt: 1,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      variant="body2"
                      color={"white"}
                    >
                      {game?.name ?? "N/A"}
                    </Typography>
                  </Item>
                </Grid>
              );
            } else {
              return (
                <Grid key={key} item md={2} xs={3}>
                  <Item>
                    <Card>
                      <ItemImage
                        component="img"
                        image={
                          params?.slug.toUpperCase() !== 'PG'
                            ? game?.img_custom ? game?.img_custom : game?.img
                            : `https://images-platform.sgp1.cdn.digitaloceanspaces.com/gamepartners/PG2/${game?.match_name.replaceAll('#', '')}.webp`
                        }
                        title={game?.name}
                        onClick={() => {
                          if (game?.agent_name === "BETFLIK") {
                            setOpen(true);
                            launchGame(
                              game?.id || "",
                              isMobile,
                              game?.is_iframe
                            );
                          } else {
                            launchGame(game?.id || "", isMobile);
                          }
                        }}
                      />
                    </Card>
                    <Typography
                      sx={{
                        mt: 1,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      variant="body2"
                      color={"white"}
                    >
                      {game?.name ?? "N/A"}
                    </Typography>
                  </Item>
                </Grid>
              );
            }
          })}
        </Grid>
      </Box>
    );
  }
}
