"use client";

import * as React from "react";
import { styled } from "@mui/material/styles";
import { Divider, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Avatar, Chip, Stack, Typography } from "@mui/material";
import { useConfigStore } from "@/stores/configStore";
import { shallow } from "zustand/shallow";
import { useAuthStore } from "@/stores/authStore";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Footer() {
  const theme = useTheme();
  const [config] = useConfigStore((state) => [state.config], shallow);
  const [user] = useAuthStore(
    (state) => [state.user!, state.logout],
    shallow
  );
  const isLoggedIn = user != null;

  return isLoggedIn && (
    <Box sx={{ flexGrow: 1, mt: "50px" }}>
      <Grid container spacing={2} sx={{ justifyContent: 'end' }}>
        <Grid item xs={12}>
          <Divider sx={{ background: '#FFE2AE', borderWidth: '1px' }} />
        </Grid>
        <Grid item xs={12} md={3} pt={0}>
          <Item sx={{
            color: (theme) => theme.gradient[700] || "white",
            textAlign: 'right',
            pt: 0
          }}>
            {
              config?.agent_name === 'wisdom168'
                ? 'Copyright © Winmasters888'
                : config?.agent_name === 'temmed'
                  ? 'Copyright © MMED'
                  : config?.agent_name === 'm150'
                    ? 'Copyright © KODMUNZ'
                    : `Copyright © ${(config?.agent_name[0]?.toUpperCase() || "") + (config?.agent_name?.slice(1).toLowerCase() || "")}`
            }
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
