"use client";

import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { green, grey, purple } from "@mui/material/colors";
import { Paper, Stack, useTheme, useMediaQuery, SwipeableDrawer, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Grid } from "@mui/material";
import { useAuthStore } from "@/stores/authStore";
import { shallow } from "zustand/shallow";
import {
  AccountBalanceWallet,
  ArrowBack,
  ContentCopy,
  CurrencyExchange,
  Notifications,
  Person4,
  Refresh,
} from "@mui/icons-material";
import { formatMoney } from "@/libs/common";
import { useRouter, usePathname } from "next/navigation";
import Image from "next/image";
import { useAuthModalStore } from "@/stores/authModalStore";
import { useAppModalStore } from "@/stores/appModalStore";
import { useConfigStore } from "@/stores/configStore";
import numeral from "numeral";
import ThemedButton from "../StyledButton";
import useMe from "@/hooks/fetchers/useMe";
import { Logo } from "../logo";
import preset5 from "@/themes/presets/preset5";
import { Icon } from "@iconify/react";
import { useUserProfile } from "@/hooks";
import { LoadingButton } from "@mui/lab";
import { DepositAndWithdrawButtonGroup } from "@/sections";
import { useSnackbar } from "notistack";

const pages = ["กิจกรรม/ข่าวสาร", "โปรโมชั่น", "บทความ"];

type RightMenu = {
  key: string;
  label: string;
  variant: "text" | "outlined" | "contained";
  color:
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning";
};

const rightMenus: RightMenu[] = [
  {
    key: "login",
    label: "เข้าสู่ระบบ",
    variant: "outlined",
    color: "success",
  },
  {
    key: "register",
    label: "สมัครสมาชิก",
    variant: "contained",
    color: "primary",
  },
];

const settings = [
  {
    key: "Profile",
    label: "โปรไฟล์",
    icon: "noto:identification-card"
  },
  {
    key: "historyAll",
    label: "ประวัติ",
    icon: "flat-color-icons:view-details",
  },
  // {
  //   key: "promotion",
  //   label: "โปรโมชั่น",
  //   icon: "noto:wrapped-gift",
  // },
  {
    key: "wheel",
    label: "กงล้อนำโชค",
    icon: "noto:ferris-wheel",
  },
  {
    key: "refund",
    label: "คืนยอดเสีย",
    icon: "flat-color-icons:money-transfer",
  },
  {
    key: "affiliate",
    label: "แนะนำเพื่อน",
    icon: "flat-color-icons:mind-map",
  },
  {
    key: "coupon",
    label: "คูปอง",
    icon: "noto:ticket",
  },
  {
    key: "mysteryBox",
    label: "กล่องสุ่ม",
    icon: "noto:gem-stone",
  },
  {
    key: "changePassword",
    label: "เปลี่ยนรหัสผ่าน",
    icon: "noto:locked-with-key"
  },
  {
    key: "Logout",
    label: "ออกจากระบบ",
    icon: "flat-color-icons:export"
  },
  // {
  //   key: "historyAll",
  //   label: "ประวัติธุรกรรม",
  //   icon: "flat-color-icons:view-details"
  // },
];

const mainSettings = [
  // { key: "deposit", text: "เติมเงิน", icon: 'solar:wallet-2-bold' },
  // { key: "withdraw", text: "ถอนเงิน", icon: 'solar:money-bag-bold' },
  { key: "Profile", text: "โปรไฟล์", icon: 'solar:card-2-bold' },
  { key: "historyAll", text: "ประวัติธุรกรรม", icon: 'solar:history-2-bold' },
  { key: "mysteryBox", text: "กล่องสุ่ม", icon: 'solar:gift-bold' },
  { key: "wheel", text: "กงล้อ", icon: 'solar:ferris-wheel-bold' },
  { key: "refund", text: "คืนยอดเสีย", icon: 'solar:hand-money-bold' },
];

const subSettings = [
  { key: "changePassword", text: "เปลี่ยนรหัสผ่าน", icon: 'solar:lock-bold' },
  { key: "Logout", text: "ออกจากระบบ", icon: 'solar:exit-bold' },
];

function LayoutAppBar() {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMD = useMediaQuery(theme.breakpoints.down("md"));
  const { enqueueSnackbar } = useSnackbar();
  const [config] = useConfigStore((state) => [state.config], shallow);
  const [user, logout] = useAuthStore(
    (state) => [state.user!, state.logout],
    shallow
  );
  const isLoggedIn = user != null;
  const [setOpenLoginModal] = useAuthModalStore(
    (state) => [state.setOpenLoginModal],
    shallow
  );
  const [setOpenAppModal, setAppDataModal] = useAppModalStore(
    (state) => [state.setOpen, state.setData],
    shallow
  );
  const [setOpen, setData] = useAppModalStore((state) => [
    state.setOpen,
    state.setData,
  ]);
  const { refresh: refreshMe } = useMe();
  const {
    isLoading,
    currentBalance,
    promotionHistory,
    handleRemovePro,
    isPromotionHistoryLoading,
    // user,
    // isBonus,
    // handleOpenModal,
  } = useUserProfile();

  const router = useRouter();
  const pathname = usePathname();

  const showArrowBack = pathname !== "/";

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleArrowBack = () => {
    refreshMe();
    if (pathname.includes("/lobbies")) {
      router.push("/");
    } else {
      router.back();
    }
  };

  const handleRightMenuClick = (key: string) => {
    switch (key) {
      case "login":
        setOpenLoginModal(true);
        break;
      case "register":
        setAppDataModal("signUp");
        setOpenAppModal(true);
        break;
      default:
        break;
    }
  };

  const handleProfileMenuClick = (key: string) => {
    handleCloseUserMenu();
    if (key == "Logout") {
      logout();
      router.push('/login')
    } else {
      setOpen(true);
      setData(key);
    }
  };

  const current_balance = numeral(user?.balance).format("0,00.00") || 0;

  const [scrollY, setScrollY] = React.useState({
    position: 0,
    direction: 'idle'
  });
  const onScroll = React.useCallback((event: any) => {
    // const { pageYOffset, scrollY } = window;
    // console.log("yOffset", pageYOffset, "scrollY", scrollY);
    const { scrollY } = window;
    setScrollY((prevState) => {
      return {
        position: scrollY,
        direction: prevState.position < scrollY ? 'down' : 'up'
      }
    });
  }, []);

  React.useEffect(() => {
    //add eventlistener to window
    window.addEventListener("scroll", onScroll, { passive: true });
    // remove event on unmount to prevent a memory leak with the cleanup
    return () => {
      window.removeEventListener("scroll", onScroll, { passive: true } as any);
    }
  }, []);

  const [spin, setSpin] = React.useState(false);

  React.useEffect(() => {
    if (spin) {
      setTimeout(() => {
        setSpin(false);
      }, 500);
    }
  }, [spin]);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent<HTMLElement>, open: boolean) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    return setState({ ...state, left: open });
  }

  const list = (anchor: string) => (
    <Box display={'flex'} flex={1} flexDirection={'column'}>
      <Box
        sx={{
          width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 280,
          mt: 5,
          mb: 3.5
        }}
        role="presentation"
      >
        <Container sx={{ padding: '0 !important' }}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
              py: 0.75,
              px: 1,
              background: 'linear-gradient(180deg, #ffe867 10%, #fe8a02);'
            }}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              {/* <Avatar
                alt="Avatar"
                src={'/assets/icons/vip1.png'}
              /> */}

              <Stack
                alignItems={"flex-start"}
                justifyContent={"center"}
                // sx={{ ml: 2 }}
                spacing={0}
              >
                {/* <Typography
                  variant="caption"
                  mb={-1.2}
                  color="white"
                  sx={{
                    ...(promotionHistory &&
                    {
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: isMobile ? '90px' : '120px'
                    })
                  }}
                >
                  {
                    user?.fullname
                  }
                </Typography> */}
                <Typography variant="h6" fontWeight={500} color="#000" sx={{
                  fontSize: 14,
                  fontWeight: 500,
                }}>
                  {user?.gameusername.toLocaleUpperCase()}
                </Typography>
              </Stack>
            </Stack>

            {/* <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Icon
                id="icon"
                icon={'material-symbols:close-rounded'}
                width={24}
                height={24}
                onClick={(e: any) => { toggleDrawer(e, false) }}
                style={{ cursor: 'pointer' }}
              />
            </Stack> */}
          </Stack>
        </Container>
      </Box>

      <Box
        flex={1}
        sx={{
          width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 280,
          px: 2.5
        }}
        role="presentation"
        onClick={(e: any) => { toggleDrawer(e, false) }}
        onKeyDown={(e: any) => { toggleDrawer(e, false) }}
      >
        <Grid container spacing={0}>
          <Grid
            item
            xs={4}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              // mr={4}
              sx={{
                cursor: 'pointer',
                transition: "all 0.12s ease-in-out",
                "&:hover": {
                  transform: "scale(1.08)",
                }
              }}
              onClick={() => {
              }}
            >
              <Image
                src={`https://auto.tem-max.net/assets/images/ic_home.png?v10`}
                width={50}
                height={50}
                alt={"https://auto.tem-max.net/assets/images/ic_home.png?v10"}
              />
              <Typography variant="subtitle2" color={theme.palette.primary.main} sx={{ fontSize: '0.75rem;', mt: 0.5 }}>
                หน้าแรก
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={4}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              // mr={4}
              sx={{
                cursor: 'pointer',
                transition: "all 0.12s ease-in-out",
                "&:hover": {
                  transform: "scale(1.08)",
                }
              }}
              onClick={() => {
                setOpen(true)
                setData('promotion')
              }}
            >
              <Image
                src={`https://auto.tem-max.net/assets/images/ic_promotion.png?v10`}
                width={50}
                height={50}
                alt={"https://auto.tem-max.net/assets/images/ic_promotion.png?v10"}
              />
              <Typography variant="subtitle2" color={theme.palette.primary.main} sx={{ fontSize: '0.75rem;', mt: 0.5 }}>
                โปรโมชั่น
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={4}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              // mr={2}
              sx={{
                cursor: 'pointer',
                transition: "all 0.12s ease-in-out",
                "&:hover": {
                  transform: "scale(1.08)",
                }
              }}
              onClick={(e) => {
                e.preventDefault()
                const newWindow = window.open(config?.line_oa_url, '_blank', 'noopener,noreferrer')
                if (newWindow) newWindow.opener = null
              }}
            >
              <Image
                src={`https://auto.tem-max.net/assets/images/ic_contact.png?v10`}
                width={50}
                height={50}
                alt={"https://auto.tem-max.net/assets/images/ic_contact.png?v10"}
              />
              <Typography variant="subtitle2" color={theme.palette.primary.main} sx={{ fontSize: '0.75rem;', mt: 0.5 }}>
                ติดต่อเรา
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ background: '#FFE2AE', borderWidth: '1px', mt: 2, mb: 3 }} />
        <Grid container spacing={0} mb={3}>
          <Grid
            item
            xs={4}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              // mr={4}
              sx={{
                cursor: 'pointer',
                transition: "all 0.12s ease-in-out",
                "&:hover": {
                  transform: "scale(1.08)",
                }
              }}
              onClick={() => {
                setOpen(true)
                setData('Profile')
              }}
            >
              <Image
                src={`https://auto.tem-max.net/assets/images/ic_profile.png?v10`}
                width={50}
                height={50}
                alt={"https://auto.tem-max.net/assets/images/ic_profile.png?v10"}
              />
              <Typography variant="subtitle2" color={theme.palette.primary.main} sx={{ fontSize: '0.75rem;', mt: 0.5 }}>
                โปรไฟล์
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={4}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              // mr={4}
              sx={{
                cursor: 'pointer',
                transition: "all 0.12s ease-in-out",
                "&:hover": {
                  transform: "scale(1.08)",
                }
              }}
              onClick={() => {
                setOpen(true)
                setData('wheel')
              }}
            >
              <Image
                src={`https://images-platform.sgp1.cdn.digitaloceanspaces.com/test/wheel.png`}
                width={50}
                height={50}
                alt={"https://images-platform.sgp1.cdn.digitaloceanspaces.com/test/wheel.png"}
              />
              <Typography variant="subtitle2" color={theme.palette.primary.main} sx={{ fontSize: '0.75rem;', mt: 0.5 }}>
                กงล้อ
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={4}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              // mr={2}
              sx={{
                cursor: 'pointer',
                transition: "all 0.12s ease-in-out",
                "&:hover": {
                  transform: "scale(1.08)",
                }
              }}
              onClick={(e) => {
                setOpen(true)
                setData('historyAll')
              }}
            >
              <Image
                src={`https://auto.tem-max.net/assets/images/ic_history.png?v10`}
                width={50}
                height={50}
                alt={"https://auto.tem-max.net/assets/images/ic_history.png?v10"}
              />
              <Typography variant="subtitle2" color={theme.palette.primary.main} sx={{ fontSize: '0.75rem;', mt: 0.5 }}>
                ประวัติ
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={0} mb={3}>
          <Grid
            item
            xs={4}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              // mr={4}
              sx={{
                cursor: 'pointer',
                transition: "all 0.12s ease-in-out",
                "&:hover": {
                  transform: "scale(1.08)",
                }
              }}
              onClick={() => {
                setOpen(true)
                setData('refund')
              }}
            >
              <Image
                src={`https://images-platform.sgp1.cdn.digitaloceanspaces.com/test/cashback.png`}
                width={50}
                height={50}
                alt={"https://images-platform.sgp1.cdn.digitaloceanspaces.com/test/cashback.png"}
              />
              <Typography variant="subtitle2" color={theme.palette.primary.main} sx={{ fontSize: '0.75rem;', mt: 0.5 }}>
                คืนยอดเสีย
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={4}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              // mr={4}
              sx={{
                cursor: 'pointer',
                transition: "all 0.12s ease-in-out",
                "&:hover": {
                  transform: "scale(1.08)",
                }
              }}
              onClick={() => {
                setOpen(true)
                setData('affiliate')
              }}
            >
              <Image
                src={`https://images-platform.sgp1.cdn.digitaloceanspaces.com/test/affiliate.png`}
                width={50}
                height={50}
                alt={"https://images-platform.sgp1.cdn.digitaloceanspaces.com/test/affiliate.png"}
              />
              <Typography variant="subtitle2" color={theme.palette.primary.main} sx={{ fontSize: '0.75rem;', mt: 0.5 }}>
                แนะนำเพื่อน
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={4}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              // mr={2}
              sx={{
                cursor: 'pointer',
                transition: "all 0.12s ease-in-out",
                "&:hover": {
                  transform: "scale(1.08)",
                }
              }}
              onClick={(e) => {
                setOpen(true)
                setData('mysteryBox')
              }}
            >
              <Image
                src={`https://images-platform.sgp1.cdn.digitaloceanspaces.com/test/mysterybox.png`}
                width={50}
                height={50}
                alt={"https://images-platform.sgp1.cdn.digitaloceanspaces.com/test/mysterybox.png"}
              />
              <Typography variant="subtitle2" color={theme.palette.primary.main} sx={{ fontSize: '0.75rem;', mt: 0.5 }}>
                กล่องสุ่ม
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid
            item
            xs={4}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              // mr={4}
              sx={{
                cursor: 'pointer',
                transition: "all 0.12s ease-in-out",
                "&:hover": {
                  transform: "scale(1.08)",
                }
              }}
              onClick={() => {
                setOpen(true)
                setData('coupon')
              }}
            >
              <Image
                src={`https://images-platform.sgp1.cdn.digitaloceanspaces.com/test/coupon.png`}
                width={50}
                height={50}
                alt={"https://images-platform.sgp1.cdn.digitaloceanspaces.com/test/coupon.png"}
              />
              <Typography variant="subtitle2" color={theme.palette.primary.main} sx={{ fontSize: '0.75rem;', mt: 0.5 }}>
                คูปอง
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={4}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              // mr={4}
              sx={{
                cursor: 'pointer',
                transition: "all 0.12s ease-in-out",
                "&:hover": {
                  transform: "scale(1.08)",
                }
              }}
              onClick={() => {
                setOpen(true)
                setData('changePassword')
              }}
            >
              <Image
                src={`https://images-platform.sgp1.cdn.digitaloceanspaces.com/test/reset_password.png`}
                width={50}
                height={50}
                alt={"https://images-platform.sgp1.cdn.digitaloceanspaces.com/test/reset_password.png"}
              />
              <Typography variant="subtitle2" color={theme.palette.primary.main} sx={{ fontSize: '0.75rem;', mt: 0.5 }}>
                แก้ไขรหัสผ่าน
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={4}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              // mr={2}
              sx={{
                cursor: 'pointer',
                transition: "all 0.12s ease-in-out",
                "&:hover": {
                  transform: "scale(1.08)",
                }
              }}
              onClick={(e) => {
                router.push("/login");
                logout();
              }}
            >
              <Image
                src={`https://auto.tem-max.net/assets/images/ic_logout.png?v10`}
                width={50}
                height={50}
                alt={"https://auto.tem-max.net/assets/images/ic_logout.png?v10"}
              />
              <Typography variant="subtitle2" color={theme.palette.primary.main} sx={{ fontSize: '0.75rem;', mt: 0.5 }}>
                ออกจากระบบ
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );

  return isLoggedIn && (
    <AppBar
      position="fixed"
      variant="elevation"
      elevation={0}
      sx={{
        background: theme.gradient[400],
        ...((theme.name == 'preset5' || theme.name == 'preset23') && {
          // backgroundImage: `url(${theme.backgroundImage.body})`
          backgroundColor: "rgba(0,0,0,0.8)"
        }),
        ...(theme.name == 'preset16' && {
          boxShadow: '#0003 0 4px 6px -1px,#0000001f 0 2px 4px -1px'
        })
      }}
    >
      <Container maxWidth="xl">
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack
            direction={"row"}
            justifyContent={"start"}
            alignItems={"center"}
          >
            {showArrowBack && (
              <IconButton
                style={{
                  border: "1.5px solid white",
                  height: "28px",
                  width: "28px",
                }}
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleArrowBack}
                color="inherit"
              >
                <ArrowBack style={{ fontSize: "18px" }} />
              </IconButton>
            )}
            <Box py={`${theme.config.logo.appBarSpacing}px`}>
              <Logo
                src={config?.logo || ""}
                height={theme.config.logo.appBarHeight}
              />
            </Box>
          </Stack>
          <Box>
            {!isLoggedIn && (
              <Stack direction={"row"} spacing={1}>
                {rightMenus.map((menu) => (
                  <Button
                    size="large"
                    variant={menu.variant}
                    key={menu.key}
                    color={menu.color}
                    onClick={() => handleRightMenuClick(menu.key)}
                    sx={
                      theme.name === 'preset14' || theme.name == 'preset33'
                        ? menu.variant === 'outlined'
                          ? {
                            borderColor: '#ffc100',
                            backgroundColor: '#f7ac36',
                            color: '#fff',
                            "&:hover": {
                              borderColor: '#ffc100',
                              backgroundColor: '#f7ac36',
                              color: '#fff',
                            },
                          }
                          : {
                            borderColor: '#33B7FF',
                            backgroundColor: '#33B7ff',
                            color: '#fff',
                            "&:hover": {
                              borderColor: '#33B7FF',
                              backgroundColor: '#33B7ff',
                              color: '#fff',
                            },
                          }
                        :
                        theme.name === 'preset34'
                          ? menu.variant === 'outlined'
                            ? {
                              borderColor: '#ffc100',
                              backgroundColor: 'transparent',
                              color: '#fff',
                              "&:hover": {
                                borderColor: '#ffc100',
                                backgroundColor: 'transparent',
                                color: '#fff',
                              },
                            }
                            : {
                              borderColor: '#d7b556',
                              backgroundColor: '#d7b556',
                              color: '#fff',
                              "&:hover": {
                                borderColor: '#d7b556',
                                backgroundColor: '#d7b556',
                                color: '#fff',
                              },
                            }
                          : {}
                    }
                  >
                    {menu.label}
                  </Button>
                ))}
              </Stack>
            )}

            {isLoggedIn && (
              <Box display={"flex"}>
                {
                  !isMD && (
                    <>
                      <Box display="inline" mr={1} mt={1}>
                        <Box display="flex">
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            mr={4}
                            sx={{
                              cursor: 'pointer',
                              transition: "all 0.12s ease-in-out",
                              "&:hover": {
                                transform: "scale(1.08)",
                              }
                            }}
                            onClick={() => {
                              setOpen(true)
                              setData('deposit')
                            }}
                          >
                            <Icon
                              id="icon"
                              icon={"noto:dollar-banknote"}
                              width={32}
                              height={32}
                            />
                            <Typography variant="subtitle2" color="white">
                              ฝากเงิน
                            </Typography>
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            mr={4}
                            sx={{
                              cursor: 'pointer',
                              transition: "all 0.12s ease-in-out",
                              "&:hover": {
                                transform: "scale(1.08)",
                              }
                            }}
                            onClick={() => {
                              setOpen(true)
                              setData('withdraw')
                            }}
                          >
                            <Icon
                              id="icon"
                              icon={"noto:money-bag"}
                              width={32}
                              height={32}
                            />
                            <Typography variant="subtitle2" color="white">
                              ถอน
                            </Typography>
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            mr={2}
                            sx={{
                              cursor: 'pointer',
                              transition: "all 0.12s ease-in-out",
                              "&:hover": {
                                transform: "scale(1.08)",
                              }
                            }}
                            onClick={() => {
                              setOpen(true)
                              setData('promotion')
                            }}
                          >
                            <Icon
                              id="icon"
                              icon={"noto:wrapped-gift"}
                              width={32}
                              height={32}
                            />
                            <Typography variant="subtitle2" color="white">
                              แพ็คเกจ
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </>
                  )
                }
                {
                  (promotionHistory && currentBalance <= 5) && (
                    <Stack
                      alignItems={"flex-end"}
                      justifyContent={"center"}
                      spacing={0}
                    >
                      <LoadingButton
                        style={{ borderRadius: 32, marginRight: '8px' }}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          handleRemovePro()
                        }}
                        loading={isLoading}
                        sx={{
                          backgroundColor: (theme) => theme.name === 'preset14'
                            ? "#790e50"
                            : "default"
                        }}
                      >
                        <Typography variant="subtitle1" fontWeight={500} color="1a2c38">
                          ออกจากโปร
                        </Typography>
                      </LoadingButton>
                    </Stack>
                  )
                }
                <Stack
                  alignItems={"flex-end"}
                  justifyContent={"center"}
                  spacing={0}
                >
                  <Box sx={{ mr: 0 }}>
                    <IconButton
                      style={{
                        padding: '0 3px'
                      }}
                      aria-label="clear"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={() => {
                      }}
                      color="inherit"
                    >
                      <Icon
                        id="icon"
                        icon={"emojione:flag-for-thailand"}
                        width={18}
                        height={18}
                        style={{ marginTop: '-2px' }}
                      />
                    </IconButton>
                    <Typography
                      variant="caption"
                      mb={-1.2}
                      color="white"
                      sx={{ fontSize: '0.875rem', marginLeft: '3px' }}
                    >
                      {`Thai`}
                    </Typography>
                  </Box>
                  <Box display={'flex'} alignItems={'center'}>
                    <LoadingButton
                      style={{ borderRadius: 32 }}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setSpin(true)
                        refreshMe()
                      }}
                      loading={spin}
                      loadingPosition="end"
                      startIcon={
                        <Icon
                          id="icon"
                          icon={"twemoji:coin"}
                          width={22}
                          height={22}
                          style={{ marginTop: '-2px' }}
                        />
                      }
                      endIcon={
                        <Icon
                          id="icon"
                          icon={"solar:refresh-bold"}
                          width={15}
                          height={15}
                          style={{
                            marginTop: '-2px',
                            color: '#fdbd00'
                          }}
                        />
                      }
                      sx={{
                        border: '2px solid #27293d;',
                        boxShadow: '0 0 5px #0000004d;',
                        backgroundColor: (theme) => theme.name === 'preset14'
                          ? "#790e50"
                          : "#0006",
                        "&:hover": {
                          backgroundColor: "#0006"
                        },
                      }}
                    >
                      <Typography variant="subtitle2" fontWeight={500} color="white">
                        {current_balance}
                      </Typography>
                    </LoadingButton>
                    <Tooltip title="Open settings">
                      <IconButton
                        onClick={
                          (e: any) => {
                            isMD ? toggleDrawer(e, true) : handleOpenUserMenu(e)
                          }
                        }
                        sx={{ p: 0 }}
                      >
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Icon
                            id="icon"
                            icon={"weui:setting-filled"}
                            width={28}
                            height={28}
                            style={{
                              marginLeft: '6px',
                              color: 'black',
                              background: config?.agent_name !== 'temmed'
                                ? '#ffffffb3'
                                : theme.palette.primary.main,
                              borderRadius: '50%',
                              padding: '4px',
                              // color: '#fdbd00',
                              // position: 'absolute',
                              // top: '2.5px',
                              // right: 0
                            }}
                          />
                          {/* <Avatar
                        alt="tooltip"
                        src={
                          promotionHistory
                            ? "https://cdn-icons-png.freepik.com/512/12534/12534332.png"
                            : config?.admin_domain
                              ? config?.admin_domain
                              : `https://cdn-icons-png.freepik.com/512/11068/11068599.png`
                        }
                      /> */}
                        </Stack>
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Stack>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting?.key}
                      onClick={() => handleProfileMenuClick(setting?.key)}
                    >
                      <Icon
                        id="icon"
                        icon={setting?.icon || ""}
                        width={24}
                        height={24}
                      />
                      <Typography textAlign="center" ml={1}>
                        {setting?.label}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            )}
          </Box>
        </Stack>
        {/* <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} /> */}
        {/* <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontWeight: 700,
              letterSpacing: ".08rem",
              color: "primary.main",
              textDecoration: "none",
            }}
          >
            ทดสอบ
          </Typography> */}
      </Container>

      <SwipeableDrawer
        anchor={'left'}
        open={state['left']}
        onClose={(e: any) => { toggleDrawer(e, false) }}
        onOpen={(e: any) => { toggleDrawer(e, true) }}
        PaperProps={{
          sx: {
            color: theme.palette.primary.main,
            backgroundColor: theme.gradient[400],
            backgroundImage: 'none'
          }
        }}
      >
        {list('left')}
      </SwipeableDrawer>

      {/* {user && (
        <Paper square>
          <Container maxWidth="md">
            <Box display={"flex"} justifyContent={"space-between"}>
              <Box
                display="flex"
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Person4 color="primary" fontSize="small" />
                <Typography>{user?.username?.toUpperCase()}</Typography>
              </Box>
              <Box
                display="flex"
                justifyContent={"center"}
                alignItems={"center"}
                gap={0.5}
              >
                <AccountBalanceWallet color="primary" fontSize="small" />
                <Typography>{formatMoney(user.point)}</Typography>
                <Refresh color="primary" fontSize="small" />
              </Box>
            </Box>
          </Container>
        </Paper>
      )} */}

      {/* {scrollY.position >= (isMobile ? 200 : 450) && (
        <Paper square sx={{
          pb: 1.5,
          background: theme.gradient[400],
          ...(theme.name == 'preset44' && {
            borderBottom: 'thin solid #1f1f2a',
          })
        }}>
          <Container maxWidth="xl">
            <DepositAndWithdrawButtonGroup />
          </Container>
        </Paper>
      )} */}

      {/* {
        (scrollY.position >= 1000 && scrollY.direction === 'up' && isMobile) && (
          <Box
            sx={{
              display: true ? 'flex' : 'none',
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)",
              cursor: "pointer",
              position: "fixed",
              width: "48px",
              height: "48px",
              backgroundColor: "red",
              borderRadius: "12px",
              top: "unset",
              bottom: isMobile ? "45px" : "0",
              right: isMobile ? "initital" : "0",
              left: isMobile ? "4px" : "initital",
              backgroundImage: "linear-gradient(0deg, #2f2f2f 0%, #4f4f4f 100%)",
              zIndex: 1000,
              justifyContent: "center",
              alignItems: "center",
              margin: 3,
              // ...((!isLoggedIn && !isMdUp) && {
              //   marginBottom: '80px'
              // }),
            }}
            onClick={() => window.scrollTo(0, 1)}
          >
            <Icon
              id="icon"
              icon={'solar:arrow-up-bold'}
              width={24}
              height={24}
            />
          </Box>
        )
      } */}
    </AppBar>
  );
}
export default LayoutAppBar;
